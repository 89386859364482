// IMPORTS
@import (reference) "settings.less";

// NEWSLETTER
#newsletter {
    position: relative;
    color: white;
    height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.newsletter-bg {
    .center-lr();
    top: 0;
    width: 101vw;
    height: 100%;
    z-index: -1;
    background-color: @main-color;
    background-position: center center;
}

.newsletter-header {
    text-align: center;

    b {
        font-size: 28px;
        display: block;
    }

    p {
        margin-top: 4px;
    }
}

.newsletter-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;
    width: 774px;

    input[type="text"] {
        width: 100%;
        height: 52px;
        background-color: transparent;
        border: 2px solid white;
        text-align: center;
        color: white;
        font-size: 16px;
        font-family: @main-font-family;

        &:focus::placeholder {
            color: transparent;
        }

        &::-webkit-input-placeholder {
            color: white;
        }
        &::-moz-placeholder {
            color: white;
        }
        &:-ms-input-placeholder {
            color: white;
        }
        &:-moz-placeholder {
            color: white;
        }
    }

    .popup-trigger {
        margin-top: 7px;
        background-color: white;
        color: @header-color;
        text-transform: uppercase;
        font-weight: 600;
        width: 130px;
        line-height: 3.2;
        text-align: center;
        cursor: pointer;
        border-radius: var(--borderRadius);

        &:hover {
            background-color: @header-color;
            color: white;
        }
    }
}

.newsletter-clauses-wrapper.unchecked:after {
    content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
    clear: both;
    color: @error-color;
    margin-top: 10px;
}

// PRODUCERS BANNER
.producers-banner {
    padding: 0 87px;
    position: relative;

    .swiper-slide {
        width: 160px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: filter 0.2s ease-in-out;
    }

    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }
}

@media (min-width: 1280px) {
    .producers-banner {

        .swiper-slide {
            width: ~"calc((100% - (5 * 61px)) / 6)";
            margin-right: 61px;

            &:hover {
                filter: grayscale(1);
            }
        }
    }
}

// FOOTER
#footer {
    position: relative;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -2;
        background-color: #2C3740;
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255,255,255,.31);
    padding: 72px 0;
    gap: 20px;
}

.footer-item {

    > strong {
        color: white;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
        display: block;
    }

    &.submenu {

        ul {
            columns: 2;
            column-gap: 30px;
        }

        a {
            display: inline-block;
            padding: 8px 0;
            line-height: 21px;
            color: #C5C5C5;

            &:hover {
                color: white;
            }
        }

        .selected a {
            text-decoration: underline;
            color: #fff;
        }
    }

    &.shortcut {

        div {
            padding-top: 18px;
        }

        a {
            display: block;
            color: #C5C5C5;
            padding: 10px 30px;
            border: 1px solid #C5C5C5;
            text-align: center;
            margin-bottom: 8px;
            border-radius: var(--borderRadius);

            &:hover {
                color: white;
                border-color: white;
            }
        }
    }

    &.contact {

    }

    &#footer-poll {
        flex-basis: 20%;
        color: #C5C5C5; 

        > b {
            padding: 8px 0 14px;
            display: block;
            color: #fff;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;

            li {
                display: flex;
                gap: 8px;
                cursor: pointer;

                &[data-poll-vote-id] {
                    align-items: center;
                    
                    &:before {
                        content: '';
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 2px solid @main-color;
                    }

                    &:hover {
                        color: #fff;

                        &:before {
                            background-color: @main-color;
                        }
                    }
                }

                &:not([data-poll-vote-id]) {
                    flex-direction: column;
                    gap: 3px;

                    div {
                        position: relative;
                        color: white;
                        padding: 3px 10px;
                    }

                    i {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        background-color: @main-color;
                        z-index: -1;
                        border-radius: var(--borderRadius);
                    }
                }
            }
        }
    }
}

.footer-contact {
    padding-top: 8px;
    overflow: hidden;

    li {
        line-height: 21px;
        float: left;

        &:nth-child(2) {
            margin-right: 16px;
        }
    }

    .address {
        color: #C5C5C5;
        padding-left: 26px;
        position: relative;
        margin-bottom: 13px;

        &:before {
            content: '\e808';
            font-family: icomoon;
            position: absolute;
            top: 0;
            left: 0;
            color: @main-color;
            font-size: 18px;
        }

        & + li {
            clear: left;
        }

        b {
            display: block;
        }
    }

    a {
        color: #C5C5C5;
        display: inline-flex;
        align-items: center;
        gap: 10px;

        &.phone:before {
            content: '\e80a';
            font-size: 18px;
        }

        &.email:before {
            content: '\e805';
            font-size: 14px;
        }

        &:before {
            font-family: icomoon;
            color: @main-color;
        }

        &:hover {
            color: white;
        }
    }
}

a.footer-contact-link {
    display: inline-flex;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 30px;
    align-items: center;
    gap: 8px;

    &:after {
        content: '\e800';
        font-family: icomoon;
        font-weight: normal;
        font-size: 9px;
        transform: rotate(-90deg);
    }

    &:hover {
        color: #C5C5C5;
    }
}

.footer-bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;

    a {
        color: #C5C5C5;

        &:hover {
            color: white;
        }
    }

    > span {
        color: #C5C5C5;
    }
}